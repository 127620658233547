<template>
  <b-container fluid="">
    <b-row>
      <b-col sm="12" lg="12">
        <card class="card-block card-stretch card-height print rounded" :headerClass="header.status == 'VOID' ? 'bg-dark header-invoice' : 'bg-primary header-invoice'">
          <template v-slot:headerTitle>
            <h4 class="card-title mb-0">Kwitansi SPT {{ header.no_spt }} ({{ header.status }})</h4>
          </template>
          <template v-slot:headerAction>
            <div class="invoice-btn">
              <button type="button" v-if="header.sppd_file_id && !header.voided_at" class="btn btn-primary active mr-2" @click="cetakSPTHandle()"><i class="las la-print"></i> Cetak SPPD</button>
              <b-button variant="danger" @click.prevent="cancelHandle()"><i class="las la-chevron-left"></i> Kembali ke SPT</b-button>
            </div>
          </template>
        </card>
      </b-col>
      <b-col sm="12" lg="4">
        <div class="card card-block p-card">
          <div class="profile-box">
              <div class="profile-card rounded">
                <img :src="url_img + user.path_foto" alt="profile-bg"
                    class="avatar-100 rounded d-block mx-auto img-fluid mb-3">
                <h3 class="font-600 text-white text-center">{{ user.full_name }}</h3>
                <p class="text-white text-center mb-5">{{ user.jabatan }}</p>
              </div>
              <div class="pro-content rounded">
                <div class="d-flex align-items-center mb-3">
                    <div class="p-icon mr-3">
                      <i class="las la-envelope"></i>
                    </div>
                    <p class="mb-0">{{ user.email }}</p>
                </div>
                <div class="d-flex align-items-center mb-3">
                    <div class="p-icon mr-3">
                      <i class="las la-address-card"></i>
                    </div>
                    <p class="mb-0">{{ user.nip }}</p>
                </div>
                <div class="d-flex align-items-center mb-3">
                    <div class="p-icon mr-3">
                      <i class="las la-briefcase"></i>
                    </div>
                    <p class="mb-0">{{ user.pangkat }}</p>
                </div>
              </div>
          </div>
         </div>
      </b-col>
      <b-col sm="12" lg="8">
        <card class="card-block card-stretch card-height print rounded">
          <template v-slot:body>
            <div class="col-12">
              <b-alert :show="header.voided_at" variant="dark">
                <div class="mm-alert-icon">
                  <i class="las la-window-close"></i>
                </div>
                <div class="mm-alert-text"><b>SPT VOID!</b> 
                  <p>{{ header.void_remark }}</p>
                </div>
              </b-alert>
              <div class="table-responsive-sm">
                <table class="table no-border">
                    <tbody>
                      <tr>
                        <td width="15%"><strong>Pelaksana</strong></td>
                        <td><h5><b-badge variant="light">{{ header.pelaksana_name }}</b-badge></h5></td>
                        <td><strong>Penandatangan</strong></td>
                        <td><h5><b-badge variant="light">{{ header.pttd_name }}</b-badge></h5></td>
                      </tr>
                      <tr>
                        <td><strong>PPTK</strong></td>
                        <td><h5><b-badge variant="light">{{ header.pptk_name }}</b-badge></h5></td>
                        <td><strong>Pembantu Bendahara</strong></td>
                        <td><h5><b-badge variant="light">{{ header.bendahara_name }}</b-badge></h5></td>
                      </tr>
                      <tr>
                        <td><strong>Asal</strong></td>
                        <td>{{ header.daerah_asal }}</td>
                        <td><strong>Tanggal Berangkat</strong></td>
                        <td>{{ header.tgl_berangkat }}</td>
                      </tr>
                      <tr>
                        <td><strong>Tujuan</strong></td>
                        <td>{{ header.daerah_tujuan }}</td>
                        <td><strong>Tanggal Kembali</strong></td>
                        <td>{{ header.tgl_kembali }}</td>
                      </tr>
                      <tr>
                        <td><strong>Alat Transportasi</strong></td>
                        <td colspan="3"> {{ header.transportasi }}</td>
                      </tr>
                      <tr>
                        <td><strong>Anggaran</strong></td>
                        <td colspan="3"> {{ header.anggaran_name }}</td>
                      </tr>
                      <tr>
                        <td> <strong>Dasar Pelaksana</strong> </td>
                        <td colspan="3">{{ header.dasar_pelaksana }}</td>
                      </tr>
                      <tr>
                        <td> <strong>Untuk</strong> </td>
                        <td colspan="3">{{ header.untuk }}</td>
                      </tr>
                      <tr v-if="header.settled_at == null">
                        <td> <strong>Anggaran Tersedia</strong> </td>
                        <td colspan="3">{{ header.anggaran_ready }}</td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </template>
        </card>
      </b-col>
      <b-col sm="12" lg="12">
        <card class="card-block card-stretch card-height print rounded">
          <template v-slot:body>
            
              <div class="d-none d-sm-block my-2" v-if="header.can_kwitansi">
                <div v-if="header.settled_at == null">
                  <span @click="showModalHandler('modalTransport')" class="btn btn-primary mr-2">
                    Tambah Transport
                  </span>
                  <span @click="showModalHandler('modalInap')" class="btn btn-success mr-2">
                    Tambah Penginapan
                  </span>
                  <span @click="showModalHandler('modalPengeluaran')" class="btn btn-warning mr-2">
                    Tambah Pengeluaran Lainnya
                  </span>
                </div>
                <div v-else>
                  <span v-if="!header.voided_at" @click="kwitansiHandler()" class="btn btn-primary mr-2">
                    Cetak Rumming
                  </span>
                </div>
              </div>
              
              <div class="d-block d-sm-none my-2" v-if="header.can_kwitansi">
                <div v-if="header.settled_at == null">
                  <span @click="showModalHandler('modalTransport')" class="btn btn-primary mr-2">
                    <i class="dripicons dripicons-rocket"></i>
                  </span>
                  <span @click="showModalHandler('modalInap')" class="btn btn-success mr-2">
                    <i class="dripicons dripicons-store"></i>
                  </span>
                  <span @click="showModalHandler('modalPengeluaran')" class="btn btn-warning mr-2">
                    <i class="dripicons dripicons-basket"></i>
                  </span>
                </div>
                <div v-else>
                  <span v-if="!header.voided_at" @click="kwitansiHandler()" class="btn btn-primary mr-2">
                    Cetak Rumming
                  </span>
                </div>
              </div>
              <vue-good-table
                :columns="PengeluaranColumn"
                :rows="PengeluaranRows"
                :line-numbers="true"
                :group-options="{
                  enabled: true,
                  headerPosition: 'bottom',
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'action' && header.generated_at == null">
                    <b-button size="sm" @click="showEditHandle(props)" variant="primary" class="mr-2" title="Edit" v-if="header.can_kwitansi && header.settled_at == null">
                      <i class="dripicons dripicons-pencil"></i>
                    </b-button >
                    <b-button size="sm" @click="showModalDeleteHandle(props)" v-if="header.can_kwitansi && header.settled_at == null" variant="danger" class="mr-2" title="Hapus">
                      <i class="dripicons dripicons-trash"></i>
                    </b-button >
                    <b-button size="sm" @click="showUploadHandle(props)" v-if="header.can_kwitansi && !props.row.file && header.settled_at == null" variant="warning" class="mr-2" title="Upload Struk">
                      <i class="dripicons dripicons-upload"></i>
                    </b-button >
                    <a href="#" v-if="props.row.file" @click="imagePriviewHandle(props.row.modul + props.row.reference_id)" class="btn btn-sm btn-info">
                      <i class="dripicons dripicons-photo"></i>
                    </a>
                    <photoswipe v-if="props.row.file" :items="[{
                      src: url_img+props.row.file,
                      thumbnail: url_img+props.row.file,
                      w: 800,
                      h: 600,
                      id: props.row.modul + props.row.reference_id,
                      alt: 'asd'}
                    ]" :options="{shareEl: false}" ref="pictureSwipe" class=""></photoswipe>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
              </vue-good-table>
          </template>
        </card>
      </b-col>
    </b-row>
    <!-- MODAL Hotel -->
    <b-modal :no-close-on-backdrop="true" size="lg"  id="modalInap" title="Biaya Penginapan">
      <form>
        <div class="form-row">
          <b-col md="12" class="form-group mb-3">
            <label for="name">Nama Hotel<strong>*</strong></label>
            <input type="text" class="form-control" id="name" v-model="formInap.hotel">
          </b-col>
          <b-col md="6" class="form-group mb-3">
            <label for="name">Nomor Ruangan<strong>*</strong></label>
            <input type="text" class="form-control" id="name" v-model="formInap.room">
          </b-col>
          <b-col md="6" class="form-group mb-3">
            <label for="name">Harga<strong>*</strong></label>
            <b-input-group prepend="Rp " >
              <vue-number :value="Number(formInap.harga)" 
                @input="handleNominalInap($event)" 
                class="form-control text-right" 
                @focus.native="$event.target.select()"/>
            </b-input-group>
          </b-col>
          <b-col md="6" class="form-group mb-3">
            <label for="name">Tanggal Masuk<strong>*</strong></label>
            <flat-pickr class="form-control" :config="tglConfig" @on-change="handleHargaInap" v-model="formInap.tgl_checkin"></flat-pickr>
          </b-col>
          <b-col md="6" class="form-group mb-3">
            <label for="name">Tanggal Keluar<strong>*</strong></label>
            <flat-pickr class="form-control" :config="tglConfig" v-model="formInap.tgl_checkout" @on-change="handleHargaInap"></flat-pickr>
          </b-col>
          <b-col md="6" class="form-group mb-3">
            <label for="name">Jumlah Hari</label>
            <input type="text" class="form-control" id="name" v-model="formInap.jml_hari" readonly>
          </b-col>
          <b-col md="6" class="form-group mb-3">
            <label for="name">Catatan</label>
            <input type="text" class="form-control" id="name" v-model="formInap.catatan" >
          </b-col>
          <b-col md="12" class="form-group mb-3">
          <label for="name">Jumlah</label>
          <b-input-group prepend="Rp " >
            <!-- <currency-input :value="Number(formInap.total_bayar)" 
              @input="formInap.total_bayar = $event"
              class="form-control text-right" readonly /> -->
              <vue-number :value="Number(formInap.total_bayar)" 
                @input="formInap.total_bayar = $event"
                class="form-control text-right" 
                readonly />
          </b-input-group>
          </b-col>
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          Batal
        </b-button>
        <b-button variant="primary" @click="modalInapHandle()">
          Simpan
        </b-button>
      </template>
    </b-modal>
    
    <b-modal :no-close-on-backdrop="true" id="modalPengeluaran" title="Tambah Pengeluaran" no-stacking>
      <form>
        <div class="form-row">
          <b-col md="12" class="form-group mb-2">
            <label for="name">Kategori<strong>*</strong></label>
            <v-select :options="opt.katPeng" v-model="formPengeluaran.kategori" class="style-chooser1"></v-select>
          </b-col>
        </div>
        <div class="form-row">
          <b-col md="12" class="form-group mb-2">
            <label for="name">Tanggal<strong>*</strong></label>
            <flat-pickr class="form-control" :config="tglConfig" v-model="formPengeluaran.tgl"></flat-pickr>
          </b-col>
        </div>
        <div class="form-row">
          <b-col md="12" class="form-group mb-2">
            <label for="name">Nominal<strong>*</strong></label>
            <b-input-group prepend="Rp " >
              <vue-number :value="Number(formPengeluaran.nominal)" 
                @input="handleNominal($event)"
                class="form-control text-right" 
                @focus.native="$event.target.select()"/>
            </b-input-group>
          </b-col>
        </div>
        <div class="form-row">
          <b-col md="12" class="form-group mb-2">
            <label for="name">Jumlah<strong>*</strong></label>
            <input type="text" class="form-control" id="name" @input="pengSatuanOnChange" v-model="formPengeluaran.jml">
          </b-col>
          <b-col md="12" class="form-group mb-2">
            <label for="name">Satuan<strong>*</strong></label>
            <v-select :options="opt.satuan" @input="pengSatuanOnChange" v-model="formPengeluaran.satuan" class="style-chooser1"></v-select>
          </b-col>
        </div>
        <div class="form-group">
          <label for="name">Catatan</label>
          <input type="text" class="form-control" id="catatan" v-model="formPengeluaran.catatan" >
        </div>
        <div class="form-row">
          <b-col md="12" class="form-group mb-2">
            <label for="name">Total</label>
            <b-input-group prepend="Rp " >
              <vue-number :value="Number(formPengeluaran.total)" 
                class="form-control text-right" 
                readonly/>
            </b-input-group>
          </b-col>
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          Batal
        </b-button>
        <b-button variant="primary" @click="modalPengeluaranSaveHandle()">
          Simpan
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL Transport -->
    <b-modal :no-close-on-backdrop="true" size="md" id="modalTransport" title="Biaya Transportasi">
      <form>
        <div class="form-group">
          <label for="jenis_transport">Tipe Transportasi<strong>*</strong></label>
          <v-select :options="opt.tipeTransport" v-model="formTransport.jenis_transport" class="style-chooser1"></v-select>
        </div>
        <div class="form-group">
          <label for="perjalanan">Jenis Perjalanan<strong>*</strong></label>
          <v-select :options="opt.tipeJalan" v-model="formTransport.perjalanan" class="style-chooser1"></v-select>
        </div>
        <div class="form-group">
          <label for="agen">Agen<strong>*</strong></label>
          <input type="text" class="form-control" id="agen" v-model="formTransport.agen">
        </div>
        <div class="form-group">
          <label for="no_tiket">Nomor Tiket<strong>*</strong></label>
          <input type="text" class="form-control" id="no_tiket" v-model="formTransport.no_tiket">
        </div>
        <div class="form-group" v-show="this.formTransport.jenis_transport === 'Pesawat'">
          <label for="kode_booking">Kode Booking</label>
          <input type="text" class="form-control" id="kode_booking" v-model="formTransport.kode_booking">
        </div>
        <div class="form-group" v-show="this.formTransport.jenis_transport === 'Pesawat'">
          <label for="no_penerbangan">Nomor Penerbangan</label>
          <input type="text" class="form-control" id="no_penerbangan" v-model="formTransport.no_penerbangan">
        </div>
        <div class="form-group">
          <label for="tgl">Tanggal Perjalanan<strong>*</strong></label>
          <flat-pickr class="form-control" :config="tglConfig"  v-model="formTransport.tgl"></flat-pickr>
        </div>
        <div class="form-group">
          <label for="total_bayar">Biaya<strong>*</strong></label>
          <b-input-group prepend="Rp " >
            <vue-number :value="Number(formTransport.total_bayar)" 
              @input="formTransport.total_bayar = $event" 
              class="form-control text-right" 
              @focus.native="$event.target.select()"/>
          </b-input-group>
        </div>
        <div class="form-group">
          <label for="name">Catatan</label>
          <input type="text" class="form-control" id="catatan" v-model="formTransport.catatan" >
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          Batal
        </b-button>
        <b-button variant="primary" @click="modalTransportHandle()">
          Simpan
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL DELETE -->
    <b-modal :no-close-on-backdrop="true" id="modalDelete" title="Hapus Data">
      <p>Apakah anda yakin untuk menghapus <strong>{{ deleteForm.label }}</strong> ?</p>
      <template #modal-footer="{ cancel }">
        <b-button variant="primary" @click="cancel()">
          Batal
        </b-button>
        <b-button variant="danger" @click="modalDeleteHandle()">
          Hapus
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL Upload -->
    <b-modal :no-close-on-backdrop="true" id="modalUpload" title="Upload File">
      <form>
        <div class="form-group">
          <label for="name">Jenis</label>
          <input type="text" class="form-control" id="catatan" v-model="formUpload.tipe" readonly>
        </div>
        <div class="form-group">
          <label for="name">Nama</label>
          <input type="text" class="form-control" id="catatan" v-model="formUpload.nama" readonly>
        </div>
        <div class="form-row">
          <b-col md="12" class="form-group mb-2">
            <label for="ss">Berkas Struk</label>
            <div class="form-group">
              <div class="custom-file">
                <input type="file" ref="file" class="custom-file-input" id="foto" v-on:change="handleFile()" required>
                <label class="custom-file-label" for="customFile">{{ formUpload.label }}</label>
              </div>
            </div>
            <template v-if="formUpload.preview">
              <div class="border p-2 mt-3">
                <p class="mb-0">Nama Berkas: {{ formUpload.file.name }}</p>
                <img :src="formUpload.preview" class="img-fluid" />
              </div>
            </template>
          </b-col>
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          Batal
        </b-button>
        <b-button variant="primary" @click="uploadStruk()">
          Simpan
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<style>
  .kapital {
    text-transform: capitalize;
  }
  .style-chooser1 .vs__search::placeholder,
  .style-chooser1 .vs__dropdown-toggle {
    height: 45px;
  }
  .profile-card::after {
   content: none;
  }
  .profile-card::before {
   content: none;
  }
</style>
<script>
  import { getDataService, postDataService, putDataService, deleteDataService } from '../../store/modules/crudservices'
  import 'vue-good-table/dist/vue-good-table.css'
  import { VueGoodTable } from 'vue-good-table'
  import { dateConfig } from '../../_utils/form-config'
  import flatPickr from 'vue-flatpickr-component'
  import 'flatpickr/dist/flatpickr.css'
  import { component as VueNumber } from '@coders-tm/vue-number-format'
  import { url_img } from '../../config/config'
  import { showToast } from '../../_utils/toast'
  import Photoswipe from '../../components/photoswipe/Photoswipe'

export default {
  name:'BidangEdit',
  components:{
    flatPickr, VueGoodTable, Photoswipe, VueNumber
  },
  data() {
    return {
      url_img: url_img,
      urlImg: null,
      urlDelete: '',
      deleteForm: {
        label: null,
        id: null
      },
      tglConfig: dateConfig.basic,
      opt:{
        tipeTransport: [],
        tipeJalan: ['Berangkat', 'Pulang'],
        katPeng: [],
        satuan: []
      },
      filteredKat: [],
      sppd_id: null,
      pegawaiId: null,
      header: {
        biaya_id: null,
        spt_id: null,
        pegawai_text: null,
        total_biaya: 0,
      },
      formPengeluaran: {
        jenis_pengeluaran: null,
        catatan: null   
      },
      formDua: {
        name: null,
        label: null,
        tipe:null,
      },
			formUpload:{
				file: null,
				preview: null,
				label: 'Pilih Berkas',
        nama: null,
        tipe: null,
        modul: null,
        id: null
			},
      formInap:{
        id: null,
        hotel: null,
        room: null,
        harga: null,
        tgl_checkin: null,
        tgl_checkout: null,
        jml_hari: null,
        total_bayar: null,
        catatan: null
      },
      formTransport:{
        id: null,
        jenis_transport: null,
        catatan: null,
        perjalanan: null,
        agen: null,
        no_tiket: null,
        kode_booking: null,
        no_penerbangan: null,
        tgl: null,
        total_bayar: null
      },
      PengeluaranColumn:[
        {
          label: 'Jenis Pengeluaran',
          field: 'tipe',
          thClass: 'text-center'
        }, {
          label: 'Pengeluaran',
          field: 'nama',
          thClass: 'text-center'
        }, {
          label: 'Tanggal',
          field: 'tanggal',
          thClass: 'text-center align-middle'
        }, {
          label: 'Keterangan',
          field: 'keterangan',
          thClass: 'text-center'
        }, {
          label: 'Nominal',
          field: 'biaya',
          thClass: 'text-center',
          tdClass: 'text-right',
          formatFn: this.formatFn,
        }, {
          label: 'Catatan',
          field: 'remark',
          thClass: 'text-center'
        }, {
          label: '',
          field: 'action',
          sortable: false,
          width: '150px',
          tdClass: 'text-center',
        }
      ],
      PengeluaranRows: [],
      user: {}
    }
  },
  methods: {
    formatFn: function(value) {
      return Number(value).toLocaleString();
    },
    //Show Modal
    cancelHandle() {
      let url = `/spt/${this.$route.params.id}/sppd`
      this.$router.push(url) 
    },
    showModalHandler(idModal) {
      this.resetModalTransport()
      this.resetModalInap()
      this.resetModalPengeluaran()

      if(idModal == 'modalPengeluaran') {
        this.getModalDua('kategori-pengeluaran')
        this.getModalDua('satuan')
      } else {
        this.getModalDua('jenis-transport')
      }
      this.$bvModal.show(idModal)
    },
    showDeleteModal(value, idModal) {
      // console.log(Object.getOwnPropertyNames(value.row), idModal)
      this.deleteForm.label = idModal === 'modalTransport' ? `Tiket ${value.row.agen}` : `Penginapan ${value.row.hotel}`
      this.deleteForm.id = value.row.id
      this.deleteForm.type = idModal
      this.$bvModal.show('modalDelete')
    },
    showUploadHandle(value) {
      this.formUpload.image=null;
      this.formUpload.file=null;
      this.formUpload.preview=null;

      this.formUpload.nama = value.row.nama
      this.formUpload.tipe = value.row.tipe
      this.formUpload.modul = value.row.modul
      this.formUpload.id = value.row.reference_id
      
      this.$bvModal.show('modalUpload')
    },
    showEditHandle(value) {
      let modul = ''
      if (value.row.modul == 'PENGELUARAN') {
        modul = 'modalPengeluaran'
        this.getPengeluaran(value.row.reference_id)
      } else if (value.row.modul == 'INAP') {
        modul = `modalInap`
        this.getInap(value.row.reference_id)
      } else if (value.row.modul == 'TRANSPORT') {
        modul = `modalTransport`
        this.getTransport(value.row.reference_id)
      } else {
        return
      }
      this.$bvModal.show(modul)
    },
    imagePriviewHandle(id) {
      // console.log(id)
      document.getElementById(id).click()
      //   let attribute = document.querySelectorAll('[data-pswp-uid="1"]')
      // console.log(attribute)
      // this.$refs.pictureSwipe.pswpsa.init()
    },
    showModalDeleteHandle(value) {
      this.deleteForm.label = value.row.nama
      this.$bvModal.show('modalDelete')
      this.urlDelete = ''
      let modul = ''

      if (value.row.modul == 'PENGELUARAN') {
        modul = '/pengeluaran'
      } else if (value.row.modul == 'INAP') {
        modul = `/inap`
      } else if (value.row.modul == 'TRANSPORT') {
        modul = `/transport`
      } else {
        return
      }
      this.urlDelete = `${modul}/${value.row.reference_id}/${this.header.biaya_id}/${this.$route.params.pegawai_id}`
    },
    //GET
    getData(id, sppd_id, user_id) {
      const url = `/spt/${id}/sppd/${sppd_id}/${user_id}`
      getDataService(url).then(response => {
        this.header = response.data

        this.$set(this.tglConfig, 'minDate', this.header.tgl_berangkat)
        this.$set(this.tglConfig, 'maxDate', this.header.tgl_kembali)
        this.getBiaya(this.header.biaya_id, this.$route.params.pegawai_id)
      })
    },
    getUser(user_id) {
      const url = `/pegawai/${user_id}`
      getDataService(url).then(response => {
        this.user = response.data
      })
    },
    getBiaya(sptId, pegawaiId) {
      const url = `/biaya/grid/${sptId}/${pegawaiId}`
      getDataService(url).then(response => {
        this.PengeluaranRows = response.data
      })
    },
    getModalDua(id) {
      const url = `${id}-search`
      getDataService(url).then(response => {
        if (id == 'kategori-pengeluaran') {
          this.opt.katPeng = response.data
        } else if (id == 'satuan') {
          this.opt.satuan  = response.data
        } else if (id == 'jenis-transport') {
          this.opt.tipeTransport  = response.data
        }
      })
    },
    getInap(id) {
      const url = `/inap/${id}`
      getDataService(url).then(response => {
        this.formInap = response.data
      })
    },
    getTransport(id) {
      const url = `/transport/${id}`
      getDataService(url).then(response => {
        this.formTransport = response.data
      })
    },
    getPengeluaran(id) {
      const url = `/pengeluaran/${id}`
      getDataService(url).then(response => {
        this.formPengeluaran = response.data
        this.getModalDua('kategori-pengeluaran')
        this.getModalDua('satuan')
      })
    },
    kwitansiHandler() {
      const url = `/spt/${this.$route.params.id}/biaya/${this.header.biaya_id}/rumming/${this.$route.params.pegawai_id}`
      getDataService(url).then(response => {
        let link = document.createElement("a")
        link.href = url_img + response.data
        link.target = "_blank"
        link.download = "rumming.pdf"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },

    //Save
    addDuaHandle() {
      const _data = {
        name: this.formDua.name
      }
      const url = this.formDua.tipe
      let parentModal = this.formDua.tipe == 'jenis-transport' ? 'modalTransport' : 'modalPengeluaran'
      postDataService(url, _data).then(response => {
        if(response.data.success){
          this.$bvModal.hide('modalDua')
          this.$bvModal.show(parentModal)
          this.getModalDua(url)
          return
        }
      })
    },
    modalTransportHandle() {
      const form = this.formTransport
      const _data = {
        id: form.id,
        biaya_id: this.header.biaya_id,
        pegawai_id: this.$route.params.pegawai_id,
        catatan: form.catatan,
        jenis_transport: form.jenis_transport,
        perjalanan: form.perjalanan,
        agen: form.agen,
        no_tiket: form.no_tiket,
        kode_booking: form.kode_booking,
        no_penerbangan: form.no_penerbangan,
        tgl: form.tgl,
        total_bayar: form.total_bayar
      }

      if(form.id){
        _data._method = 'PUT'
        putDataService('transport/'+form.id, _data).then(response => {
          if(response.data.success){
            this.getBiaya(this.header.biaya_id, this.$route.params.pegawai_id)
            this.header.total_biaya = response.data.data.total
            this.$bvModal.hide('modalTransport')
            return
          }
        })
      } else {
        postDataService('transport', _data).then(response => {
          if(response.data.success){
            this.getBiaya(this.header.biaya_id, this.$route.params.pegawai_id)
            this.header.total_biaya = response.data.data.total
            this.$bvModal.hide('modalTransport')
            return
          }
        })
      }
    },
    modalInapHandle() {
      const form = this.formInap
      const _data = {
        id: form.id,
        biaya_id: this.header.biaya_id,
        pegawai_id: this.$route.params.pegawai_id,
        hotel: form.hotel,
        room: form.room,
        harga: form.harga,
        tgl_checkin: form.tgl_checkin,
        tgl_checkout: form.tgl_checkout,
        jml_hari: form.jml_hari,
        total_bayar: form.total_bayar,
        catatan: form.catatan
      }

      if(_data.id){
        _data._method = 'PUT'
        putDataService('inap/' + form.id, _data).then(response => {
          if(response.data.success){
            this.getBiaya(this.header.biaya_id, this.$route.params.pegawai_id)
            this.header.total_biaya = response.data.data.total
            this.$bvModal.hide('modalInap')
            return
          }
        })
      } else {
        postDataService('inap', _data).then(response => {
          if(response.data.success){
            this.getBiaya(this.header.biaya_id, this.$route.params.pegawai_id)
            this.header.total_biaya = response.data.data.total
            this.$bvModal.hide('modalInap')
            return
          }
        })
      }
    },
    modalPengeluaranSaveHandle() {
      const form = this.formPengeluaran
      const _data = {
        id: form.id,
        biaya_id: this.header.biaya_id,
        pegawai_id: this.$route.params.pegawai_id,
        tgl: form.tgl,
        kategori: form.kategori,
        nominal: form.nominal,
        satuan: form.satuan,
        jml: form.jml,
        total: form.total,
        catatan: form.catatan
      }
      
      if(_data.id){
        _data._method = 'PUT'
        putDataService('pengeluaran/' + _data.id, _data).then(response => {
          if(response.data.success){
            this.getBiaya(this.header.biaya_id, this.$route.params.pegawai_id)
            this.header.total_biaya = response.data.data.total
            this.$bvModal.hide('modalPengeluaran')
            return
          }
        })
      } else {
        postDataService('pengeluaran', _data).then(response => {
          if(response.data.success){
            this.getBiaya(this.header.biaya_id, this.$route.params.pegawai_id)
            this.header.total_biaya = response.data.data.total
            this.$bvModal.hide('modalPengeluaran')
            return
          }
        })
      }
    },
    pengSatuanOnChange() {
      let nominal = Number(this.formPengeluaran.nominal)
      let jml = Number(this.formPengeluaran.jml)
      this.formPengeluaran.total = nominal * jml
    },
    handleNominal(event) {
      this.formPengeluaran.nominal = event
      this.pengSatuanOnChange()
    },
    handleNominalInap(event) {
      this.formInap.harga = event
      this.handleHargaInap()
    },
    resetModalInap() {
      this.formInap ={
        id: null,
        hotel: null,
        room: null,
        harga: null,
        tgl_checkin: null,
        tgl_checkout: null,
        jml_hari: null,
        total_bayar: null,
        catatan: null
      }
    },
    resetModalTransport() {
      this.formTransport = {
        id: null,
        biaya_id: null,
        pegawai_id: null,
        catatan: null,
        jenis_transport: null,
        perjalanan: null,
        agen: null,
        no_tiket: null,
        kode_booking: null,
        no_penerbangan: null,
        tgl: null,
        total_bayar: null
      }
    },
    resetModalPengeluaran() {
      this.formPengeluaran = {
        id: null,
        biaya_id: null,
        pegawai_id: null,
        tgl: null,
        kategori: null,
        nominal: null,
        satuan: null,
        jml: null,
        total: null,
        catatan: null
      }
    },
    modalDeleteHandle() {
      deleteDataService(this.urlDelete).then(response => {
        if(response.data.success){
          this.getBiaya(this.header.biaya_id, this.$route.params.pegawai_id)
          this.header.total_biaya = response.data.data.total
          this.$bvModal.hide('modalDelete')
        }
      })
    },
		uploadStruk(){
      const form = this.formUpload
			
			if (form.file == null) { 
				showToast('error', 'File tidak boleh kosong.')
				return 
			}

			const _formData = new FormData()
      _formData.append('_method', 'PUT' )
      _formData.append('file', form.file)
      
			let url = ''
      if(this.formUpload.modul == 'INAP') {
        url = `/inap/${this.formUpload.id}/upload`
      } else if (this.formUpload.modul == 'PENGELUARAN') {
        url = `/pengeluaran/${this.formUpload.id}/upload`
      } else if (this.formUpload.modul == 'TRANSPORT') {
        url = `/transport/${this.formUpload.id}/upload`
      } else {
        return
      }
      putDataService(url, _formData).then(response => {
        if (response.data.success) { 
          this.$bvModal.hide('modalUpload')
          this.getData(this.$route.params.id, this.$route.params.sppd_id, this.$route.params.pegawai_id)
        }
      })		
		},
		handleFile() {
			let input = this.$refs.file
			
			if (input) {
				var reader = new FileReader();
				reader.onload = (e) => {
					this.formUpload.preview = e.target.result;
				}
				this.formUpload.image=input.files[0];
				this.formUpload.file=input.files[0];
				reader.readAsDataURL(input.files[0]);
			}
		},
    handleHargaInap() {
      const msperday = 1000 * 60 * 60 * 24
      let startDate = new Date(this.formInap.tgl_checkin)
      let endDate = new Date(this.formInap.tgl_checkout)
      // console.log(startDate, endDate)
      // let endDate = new Date(props)

      let diff = Math.ceil((endDate - startDate) / msperday)
      let valueDiff = diff === 0 ? 1 : diff
      this.formInap.jml_hari = valueDiff
      this.formInap.total_bayar = this.formInap.harga * valueDiff
    },
    formCancel(){
      this.$router.push(`/spt/${this.$route.params.id}/sppd`) 
    },
    cetakSPTHandle() {
      const url = `/spt/cetak-sppd/${this.$route.params.sppd_id}`
      getDataService(url).then(response => {
        let link = document.createElement("a")
        link.href = url_img + response.data
        link.download = (new Date).toLocaleDateString() + "_sppd.pdf"
        link.target = "_blank"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
  },
  created() {
    this.getData(this.$route.params.id, this.$route.params.sppd_id, this.$route.params.pegawai_id)
    this.getUser(this.$route.params.pegawai_id)
    // this.getBiaya(this.header.biaya_id, this.$route.params.pegawai_id)
    this.sppd_id = this.$route.params.sppd_id
    this.pegawaiId = this.$route.params.pegawai_id
  },
  mounted() {
    // setTimeout(() => {
    //   this.loaded = true
    // }, 500);
  },
}
</script>